<template>
  <div
    class="background-cover-event-disable"
  />
  <div
    class="confirm-disable-popup-wrapper"
  >
    <div class="confirm-disable-header">
      Disable event?
    </div>
    <div class="confirm-disable-message">
      If you proceed
      <span>{{ eventName }}</span>
      will be disabled permanently.
    </div>
    <div class="confirm-disable-warning-message-wrapper">
      <div class="confirm-disable-warning-message">
        <Icon :name="'alert-cycle'" />
        You will not be able to undo this action.
      </div>
    </div>
    <div class="confirm-disable-buttons">
      <Button
        @click="leaveCancel"
      >
        Dismiss
      </Button>
      <Button
        variant="danger"
        @click="leaveConfirm"
      >
        Disable
      </Button>
    </div>
  </div>
</template>
<script>
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';

export default {
  emits: ['onLeaveCancel', 'onLeaveConfirm'],
  components: {
    Button,
    Icon,
  },
  props: {
    eventName: {
      type: String,
      default: () => 'event',
    },
  },
  setup(props, { emit }) {
    const leaveCancel = () => emit('onLeaveCancel');
    const leaveConfirm = () => emit('onLeaveConfirm');

    return {
      leaveCancel,
      leaveConfirm,
    };
  },
};
</script>
<style lang="scss">
.background-cover-event-disable {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(#000, 0.5);
  z-index: $modalBackgroundZIndex;
  left: 0;
  top: 0;
}
.confirm-disable-popup-wrapper {
    position: absolute;
    width: 360px;
    min-height: 151px;
    top: 0;
    right: 0;
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    z-index: $modalZIndex;

    .confirm-disable-header {
      font-size: 15.72px;
      color: #191414;
      font-weight: 600;
      padding: 16px;
      border-bottom: 1px solid $gray400;

    }

    .confirm-disable-message {
        padding: 16px;

        span {
          font-weight: 600;
          color: #191414;
        }
    }

    .confirm-disable-warning-message-wrapper {
      width: 100%;
      padding: 0 16px;
      margin-bottom: 16px;

      .confirm-disable-warning-message {
        width: 100%;
        height: 32px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        background-color: #FFEEEE;
        padding: 0 8px;
        margin-bottom: 16px;

        .icon {
          height: 16px;
          width: 16px;
          margin-right: 4px;

          svg {
            height: 16px;
            width: 16px;
            path {
              stroke: #FF2E2D;
              stroke-width: 2;
            }
          }
        }
      }
    }

    .confirm-disable-buttons {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      padding: 16px;
      border-top: 1px solid $gray400;

      .button {
        padding: 8px 24px;
      }
    }
}
</style>
